.container{
    overflow-x: hidden;
  }

.content {
    position: relative;
    z-index: 2;
    padding-top: 15%; 
  }
  
@media (max-width: 768px) {
    .content {
        padding-top: 30%; 
      }
}