.background-container {
    position: relative;
    min-height: 100vh;
    background-size: cover;
    overflow: hidden; 
  }
  
  .background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    opacity: 0.1; 
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    padding-top: 15%; 
  }

  /* make headers vertical */
  .vertical-text {
    writing-mode: vertical-rl;
    transform:scale(-1);
    opacity: 75%;
    font-weight: bold;
    font-family: 'ZT-Kis';
  }
  
  #productImg {
    height: 900px;
}

.storyCol{
  padding-top: 8%;
  padding-right: 15%;
}

/* style will follow this if the screen is =< 768px (md) */
@media (max-width: 768px) {
  .content {
    padding-top: 30%; 
  }
  
  .vertical-text {
    writing-mode: horizontal-tb;
    transform:scale(1);
  }

  #productImg {
    height: 500px;
  }

  .storyCol{
    padding-right: 5%;
    padding-left: 5%;
  }
}