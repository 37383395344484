.navbar {
  display: flex;
  position: fixed;
  width: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 1000;
}

.navbar.transparent {
  background-color: transparent;
}

/* change the navbar background to white and add a shadow to indicate the navbar */
.navbar.shrunk {
  min-height: 10vh;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-link {
  color: #213954;
  font-size: 20px;
  font-weight: bold;
  font-family: 'ZT-Kis';
}

#logo {
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out; /* Smooth transition for height and width */
}

.logoShrink {
  height: 80px !important; /* Adjust to desired height when shrunk */
  width: auto !important; /* Adjust width if necessary */
}

.transitioning {
  height: 80px; /* Ensure the height matches the transition state */
}

@media (max-width: 995px) {
  .navbar.transparent {
    background-color: white;
  }

  .nav-link {
    font-size: 16px; /* Adjust font size for smaller screens */
  }
}
