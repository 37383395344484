p.footer{
    font-size: 12px;
    font-family: 'Archivo', Archivo;
}

p.custom{
    font-family: 'ZT Kis';
}

h5.footer{
    font-weight: bold;
    font-family: 'ZT Kis';
}

.vr{
    width: 2px;
}

/* This will  remove the vertical bar when the screen gets smaller*/
/* Vertical bar wont show in the phone browser */
@media (max-width: 576px) {
    .vr {
      border-left: none;
      border-top: 3px solid white; 
      width: 100%;
      height: 0; 
    }
  }

#footerImg{
    width: 150px;
    height: 170px;
}