@font-face {
    font-family: 'ZT-Kis';
    src: url('../fonts/ZT Kis.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'ZT-Kis Bold';
    src: url('../fonts/ZT Kis Bold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'Archivo';
    src: url('../fonts/Archivo_Expanded-Regular.ttf') format('truetype');
  }
  