.background-container {
    position: relative;
    background-size: cover;
    overflow: hidden;
  }
  
  .background-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    overflow: visible;
    opacity: 1;
    z-index: 1;
  }
  
  .landingContent {
    position: relative;
    z-index: 2;
    margin-top: 8%;
  }
  
  .image-container {
    display: flex;
    justify-content: center;
    overflow: visible;
  }
  
  .customImage {
    position: relative;
    overflow: visible;
    height: 800px;
    width: auto;
  }
  
  .customText {
    font-size: 65px;
  }
  
  .customFont {
    font-family: 'ZT-kis';
  }
  
  #customCol {
    margin-top: -450px;
  }
  
  /* Media queries */
  @media (max-width: 1400px) {
    .landingContent {
      margin-top: 15%;
    }
    .customSubText {
      font-size: 1.7rem;
    }
    .customImage {
      height: 700px;
    }
  }
  
  @media (max-width: 1200px) {
    .customText {
      font-size: 2rem;
    }
    .customSubText {
      font-size: 1.6rem;
    }
  }
  
  @media (max-width: 992px) {
    .customImage {
        height: 600px;
      }

      /* decrease both header and subtext sizes */
      .customText {
        font-size: 2rem;
    }
    .customSubText {
      font-size: 1.4rem;
    }
    .landingContent {
      margin-top: 20%;
    }
  }
  
  @media (max-height: 850px) {
    .customImage {
      height: 750px;
    }
  }
  
  @media (max-height: 810px) {
    .customImage {
      height: 700px;
    }
  }
  
  @media (max-height: 753px) {
    .customImage {
      height: 650px;
    }
  }
  
  @media (max-width: 768px) {
    .customText {
      font-size: 1.8rem;
    }
    #customCol {
      margin-top: 100px;
    }
    .customImage {
      height: 500px;
    }
  }
  
  @media (max-width: 576px) {
    #customCol {
      margin-top: 80px;
    }
    .customText {
      font-size: 1.8rem;
    }
    .customSubText {
      font-size: 1.3rem;
    }
    .customImage {
      height: 500px;
    }
  }
  
  @media (max-width: 1650px) and (max-height: 750px) {
    #customCol {
      margin-top: -200px;
    }
    .customImage {
      height: 630px;
    }
  }
  
  @media (max-width: 1650px) and (max-height: 680px) {
    .customText {
      font-size: 1.9rem;
    }

    #customCol {
      margin-top: -200px;
    }
    .customImage {
      height: 550px;
    }
  }
  
  @media (max-width: 1250px) and (max-height: 550px) {
    #customCol {
      margin-top: -100px;
    }
    .customImage {
      margin-top: -50px;
      height: 490px;
    }
  }
  
  @media (max-width: 1000px) and (max-height: 850px) {
    #customCol {
      margin-top: -200px;
    }
    .customImage {
      height: 550px;
    }
  }
  
  @media (max-width: 1300px) and (max-height: 850px) {
    .customText{
      font-size: 2.2rem;
    }
  }
  
  @media (max-width: 576px) and (max-height: 950px) {
    #customCol {
      margin-top: 40%;
    }

    .customImage {
      margin-top: 5%;
      height: 550px;
    }
  }
  
  @media (max-width: 430px) and (max-height: 900px) {
    #customCol {
      margin-top: 40%;
    }

    .customImage {
      margin-top: 20%;
      height: 500px;
    }
  }
  
  @media (max-width: 480px) and (max-height: 850px) {
    .customImage {
      margin-top: -2%;
      height: 500px;
    }
  }
  
  @media (max-width: 400px) and (max-height: 850px) {
    #customCol {
      margin-top: 40%;
    }
    .customImage {
      margin-top: 0%;
      height: 460px;
    }
  }
  
  @media (max-width: 430px) and (max-height: 750px) {
    #customCol {
      margin-top: 35%;
    }
    .customImage {
      margin-top: -20%;
      height: 380px;
    }
  }
  
  @media (max-width: 380px) and (max-height: 690px) {
    .customImage {
      margin-top: -80%;
      height: 350px;
    }
  }

  @media (min-height: 560px) and (max-height: 640px) {
    .customImage {
      margin-top: -10%;
      height: 450px;
    }
  }