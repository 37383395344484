  .vertical-text {
    writing-mode: vertical-rl;
    transform: scale(-1);
    opacity: 75%;
    font-weight: bold;
    font-family: 'ZT-Kis';
  }

  .container {
    overflow-x: hidden;
  }

  .storyCol {
    padding-top: 8%;
    padding-right: 15%;
  }

  /* style will follow this if the screen is =< 768px (md) */
  @media (max-width: 768px) {
    .vertical-text {
      writing-mode: horizontal-tb;
      transform: scale(1);
    }

    .storyCol {
      padding-right: 5%;
      padding-left: 5%;
    }
  }

  /* .custom-image-container {
    display: flex;
    justify-content: center;
  } */

  /* Custom height sizing for waterfall image */
  /* .custom-image {
    max-width: 100%;
    height: auto;
    object-fit: cover;
  } */

  /* Specific breakpoints adjustments */
/* @media (max-width: 990px) and (min-width: 777px) {
  .custom-image {
    width: 400px;
  }
} */

/* styles.css */
.custom-image-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Ensures the image is centered vertically */
}

.custom-image {
  object-fit: cover;
  width: 100%;
}

/* Specific breakpoints adjustments */
@media (max-width: 990px) and (min-width: 777px) {
  .custom-image {
    height: 750px; 
  }
}

@media (min-height: 450px) {
  .custom-image {
    width: 100%;
    height: 600px; 
  }
}

@media (max-height: 450px) {
  .custom-image {
    width: 100%;
    height: 600px; 
  }
}

@media (max-height: 550px) {
  .custom-image {
    width: 100%;
    height: 650px; 
  }
}
